<template>
  <div v-if="inspectionInfo" class="payment-container">
    <div class="header">
      <img
        class="logo"
        height="35%"
        :src="require('@/assets/imgs/logo.png')"
      >
    </div>

    <Lang @changeLang="changeLang" />

    <div class="order-container">
      <div class="order-info">
        <div>{{ $t('orderNo') }}</div>
        <div>
          <span>{{ orderNo }}</span>
          <FuCopy :value="orderNo" />
        </div>
      </div>

      <div
        v-if="inspectionInfo"
      >
        <valid-time-countdown
          :expired="false"
          :is-i-d="isID"
          :expiry-date="expiryDate"
        />
      </div>

      <FuCellGroup
        :columns="paymentDetailsColumns"
        :datas="paymentDetailsInfo"
        :position-botton:="false"
      />

      <FuCellGroup
        :title="$t('policyInformation')"
        :columns="policyDetailsColumns"
        :datas="policyDetailsInfo"
        :position-botton:="false"
      />

      <FuCellGroup
        v-if="isID"
        :title="$t('vehicleInfo.vehicleInformation')"
        :columns="vehicleDetailsColumns"
        :datas="vehicleDetailsInfo"
        :position-botton:="false"
      />
    </div>

    <div v-if="!inspectionRequired" class="inspection-entry">
      <span>{{ $t('viewInspectionInformation') }}</span>
      <Icon
        name="arrow"
        @click="viewInspection"
      />
    </div>

    <div v-if="inspectionRequired" class="footer fu-fixed">
      <Button
        class="van-button--block"
        type="danger"
        @click="submitInspection"
      >{{ $t('submitInspection') }}</Button>
    </div>

    <!-- RP-4563 印尼 Live Photo 不显示 -->
    <div v-if="!inspectionRequired && !isID" class="footer fu-fixed">
      <Button
        class="th-next-btn"
        type="danger"
        color="#d9000b"
        :disabled="!!loading"
        plain
        @click="askPartnerPay"
      >
        {{ $t('complete') }}
      </Button>
      <!-- <Button
        class="th-next-btn"
        type="danger"
        color="#d9000b"
        :disabled="!!loading"
        @click="onPay"
      >
        {{ $t('pay') }}
      </Button> -->
    </div>
  </div>
</template>

<script>

import { getInspectionInfoFromToken } from './api'
import { getPolicyDetailsColumns, paymentDetailsColumns, getVehicleDetailsColumns } from './config/index'
import { toastLoading, getProjectHost } from '@/utils/index'
import ValidTimeCountdown from './component/validTimeCountdown'
import FuCellGroup from './../../components/FuCellGroup'
import FuCopy from './../../components/FuCopy'
import { Button, Icon } from 'vant'
import Lang from './component/lang'
import dao from '../../utils/dao'
import { getTenantRegion } from '@fu/core'

export default {
  name: 'Payment',
  components: {
    ValidTimeCountdown,
    FuCellGroup,
    FuCopy,
    Button,
    Lang,
    Icon
  },
  data() {
    return {
      loading: null,
      popupVisible: false,
      policyDetailsColumns: getPolicyDetailsColumns('vn'),
      policyDetailsInfo: {},
      paymentDetailsColumns: paymentDetailsColumns('vn'),
      paymentDetailsInfo: {},
      vehicleDetailsColumns: getVehicleDetailsColumns('id'),
      vehicleDetailsInfo: {},
      inspectionInfo: null,
      lang: this.$store.state.base.lang,

      cashierSysHostMap: {
        'development': `https://localhost:9090/#/customer?securityCode=`,
        'dev': `https://cashier-dev.fusenano.com/v2/#/customer?securityCode=`,
        'sit': `https://cashier-sit.fuse.com.vn/v2/#/customer?securityCode=`,
        'uat': `https://cashier-uat.fuse.com.vn/v2/#/customer?securityCode=`,
        'prod': `https://cashier.fuse.com.vn/v2/#/customer?securityCode=`
      }
    }
  },
  computed: {
    expiryDate() {
      return new Date(this.paymentDetailsInfo?.paymentDueDate) || new Date()
    },
    orderNo() {
      return this.inspectionInfo?.shareInspectionInfo?.orderNo ?? ''
    },
    inspectionRequired() {
      // inspection资料状态 1:required 2:submitted 3:rejected 4:finished
      return [1, 3].includes(this.inspectionInfo?.currentInspectionStatus)
    },
    isID() {
      return getTenantRegion() === 'ID'
    }
  },
  activated() {
    const { token } = this.$route.query

    dao.set('inspectionToken', token)

    this.getInspectionInfo(token)
  },
  deactivated() {
    this.inspectionInfo = null
  },
  methods: {
    changeLang(val) {
      this.lang = val
      this.policyDetailsColumns = getPolicyDetailsColumns(this.lang)
      this.paymentDetailsColumns = paymentDetailsColumns(this.lang)
      this.vehicleDetailsColumns = getVehicleDetailsColumns(this.lang)
    },
    async getInspectionInfo(token) {
      this.loading = toastLoading()
      const data = await getInspectionInfoFromToken(token)
      this.loading.clear()
      this.loading = null

      if (data) {
        this.policyDetailsInfo = data.shareInspectionInfo.shareInspectionPolicyInfo
        this.paymentDetailsInfo = data.shareInspectionInfo
        this.vehicleDetailsInfo = data.shareInspectionInfo.shareInspectionApplicationInfo
        this.inspectionInfo = data

        dao.set('inspectionInfo', this.inspectionInfo)
      }
    },
    async submitInspection() {
      this.$router.push(`/inspection/inspectionForm`)
    },
    async viewInspection() {
      this.$router.push(`/inspection/inspectionForm`)
    },
    askPartnerPay() {
      this.$router.push(`/inspection/completed`)
    },
    onPay() {
      const { token } = this.$route.query

      location.href = `${getProjectHost()}customer?securityCode=${encodeURIComponent(this.inspectionInfo.securityCode)}&token=${token}&region=${getTenantRegion()}&language=en-US`
    }
  }
}
</script>

  <style lang="scss" scoped>
  .payment-container {
    background-color: #fbfbfb;
    min-height: 100%;

    .fu-cell {
    padding: 0.10667rem 0;
    font-family: Roboto;
    font-style: normal;
    font-size: .32rem;
    line-height: .42667rem;
    min-height: 0.42667rem;
    color: #4b4b4b;
    background-color: #f2f2f2;
  }

    .hint {
      font-size: 16px;
      margin: 10px 0px 5px 0px;
    }

    .header {
    padding: 0 20px;
    height: 100px;
    line-height: 75px;
    background-color: #D90109;
    font-size: 0;
    text-align: center;
  }

  .order-container {
    margin: 10px;
    padding: 10px;
    box-shadow: 0px 0.4px 1.6px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    background-color: #FFFFFF;

    max-height: calc(100vh - 190px);
    overflow-y: auto;
    .order-info {
      font-size: 17px;
      background-color: #f2f2f2;
      border-radius: 7px;
      padding: 10px 13px;
      font-weight: lighter;

      span {
        font-weight: bold
      }
    }

  }
  .inspection-entry {
      background-color: #FFFFFF;
      border-radius: 15px;
      box-shadow: 0px 0.714988px 2.85995px rgba(0, 0, 0, 0.25);
      padding: 10px;
      display: flex;
      justify-content: space-between;
      margin:10px;
      font-size: 14px;
      margin-bottom: 150px;

      .van-icon {
        color: #C82A21;
      }
    }

  .van-button{
      background: #C82A21;
      box-shadow: 0px 0.714988px 2.85995px rgba(0, 0, 0, 0.25);
      border-radius: 16px;
      height: 47px;
      line-height: 47px;
      text-align: center;
      overflow: hidden;
      .van-button__text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
      }
    }

  .logo {
    text-align: center;
  }

  .footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 40px;
  background: #fff;
  box-shadow: 0px -2.5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  z-index: 1000;
  .van-button {
    // background: #C82A21;
    box-shadow: 0px 0.714988px 2.85995px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    height: 47px;
    line-height: 47px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    .van-button__text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      // color: #FFFFFF;
    }
  }

  .van-button + .van-button {
    margin-left: 10px;
  }
  }

  }

  </style>

